import ProductsContainer from 'containers/ProductsContainer';
import ProductsPromotion from 'components/contentLayouts/ProductsPromotion';
import PropTypes from 'prop-types';
import React from 'react';
import activeSetting from 'libs/wordpress/utils/activeSetting';
import decodeHTMLEntities from '@nwg/core-web/utils/decodeHTMLEntities';
import parseProductSelection from 'libs/wordpress/utils/transformProductSelection';
import { productSelectionProp } from 'utils/proptypes/modules/productProps';
import { sortArrayByTargetArray } from 'utils/array';
import transformButton from 'libs/wordpress/utils/transformButton';
import useSaveResponse from 'hooks/useSaveResponse';
import { useTranslation } from 'react-i18next';

// Pure CMS data transformation before we send it to the "real" block!
const ProductsPromotionBlock = ({ content = {}, productsQuantity = 12 }) => {
    const { t } = useTranslation();
    const { heading, heading_size: headingSize, products_selection: productsSelection, settings, button } = content;
    // Transform settings
    const hideHeading = activeSetting(settings, 'hide_heading');
    const hideButtons = activeSetting(settings, 'hide_buttons');

    // Get productSelection data
    const { type, values } = parseProductSelection(productsSelection);

    /**
     * Slice due to it being possible select more products in the CMS than the set limit of productsQuantity.
     * This can mess up the algolia request, giving us the wrong products in the wrong order.
     */
    const transformedValues = values.slice(0, productsQuantity);

    // Save response from container
    const [useResponse, saveResponse] = useSaveResponse();

    return (
        <ProductsContainer
            pageSize={productsQuantity}
            ids={type === 'id' ? transformedValues : []}
            categories={type === 'category' ? transformedValues : []}
            render={({ response }) => {
                saveResponse(response);
                const responseProducts = useResponse?.current?.products || [];
                let products = responseProducts;

                // Sort the products from Algolia in the same order as it was from the cms
                if (type === 'id') {
                    const productsSelectionIDs = transformedValues?.map(i => parseInt(i, 10)) || [];

                    const sortedProductsSelection = productsSelectionIDs
                        ? sortArrayByTargetArray(
                              productsSelectionIDs,
                              responseProducts.filter(p => productsSelectionIDs.includes(p.id)),
                              'id'
                          )
                        : [];

                    products = sortedProductsSelection;
                }

                return (
                    <ProductsPromotion
                        button={!hideButtons && button ? transformButton(button) : null}
                        heading={(!hideHeading && decodeHTMLEntities(heading?.text)) || null}
                        headingSize={headingSize}
                        headingTag={(!hideHeading && heading?.type) || null}
                        products={products}
                    />
                );
            }}
            t={t}
        />
    );
};

ProductsPromotionBlock.propTypes = {
    content: PropTypes.shape({
        button: PropTypes.object,
        heading: PropTypes.object,
        'products_selection': productSelectionProp.isRequired,
        settings: PropTypes.arrayOf(PropTypes.string),
    }),

    productsQuantity: PropTypes.number,
};

export default ProductsPromotionBlock;
