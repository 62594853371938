import MaxWidthWrapper from 'components/wrappers/MaxWidthWrapper';
import ProductsPromotionBlock from 'libs/wordpress/content/cmsBlocks/ProductsPromotionBlock';
import React from 'react';
import moduleMargins from 'config/theme/moduleMargins';

const ProductsPromotionLayout = ({ ...rest }) => (
    <MaxWidthWrapper includeContentMargins as="section" my={moduleMargins.md}>
        <ProductsPromotionBlock {...rest} />
    </MaxWidthWrapper>
);

export default ProductsPromotionLayout;
